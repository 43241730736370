<template>
  <header id="header" class="header fixed-top d-flex align-items-center z-999">

    <div class="d-flex align-items-center justify-content-between">
      <router-link :to="{name: 'Home'}" class="logo d-flex align-items-center justify-content-center">
        <img src="/assets/img/logo1.svg" alt="">
        <!-- <span class="d-none d-lg-block">NiceAdmin</span> -->
      </router-link>
      <i class="bi bi-list toggle-sidebar-btn" @click="toggleMenu()"></i>
    </div><!-- End Logo -->

    <div class="ms-auto" id="switch-port">
      <a-select @change="changeService" v-model="service" class="m-2 service" dropdownClassName="service-dropdown" v-if="isset(user.clients) && user.clients.length > 1">
        <a-select-option v-for="item in user.clients" :value="item.client_id" :key="item.domain">
          <img :src="item.client_id === service ? '/assets/img/debit-card.png' : '/assets/img/money-transfer.png'" width="20px">
          {{ item.client_id === service ? $t('Title.PaymentGatewayService') : $t('Title.PayInPayOutService') }}
        </a-select-option>
      </a-select>
    </div>

    <div>
      <SwitchButton
          :lang.sync="lang"
          @onChangeLang="onChangeLang"
      ></SwitchButton>
    </div>

    <nav class="header-nav ">
      <ul class="d-flex align-items-center">
        <li class="nav-item dropdown dropdown-avatar">

          <a class="nav-link nav-profile d-flex align-items-center pe-0 dropdown-avatar-link" href="#" data-bs-toggle="dropdown">
            <img :src="isset(user.profile.logo) ? user.profile.logo : '/assets/img/avatardefault.webp'" alt="Profile" class="rounded-circle">
            <span class="d-none d-md-block dropdown-toggle ps-2">{{ user.name ? user.name: ''}}</span>
          </a><!-- End Profile Iamge Icon -->

          <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
            <li @click="handleLogout()">
              <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                <i class="bi bi-box-arrow-right"></i>
                <span>{{ $t('Label.SignOut') }}</span>
              </a>
            </li>

          </ul><!-- End Profile Dropdown Items -->
        </li><!-- End Profile Nav -->

      </ul>
    </nav><!-- End Icons Navigation -->

  </header>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';
import SwitchButton from '@/components/SwitchButton';
import helper from '../until/helper';
import AppConstant from "../constants/App";
import OtpConstant from "@/constants/OtpConstant";

export default {
  name: "Header",
  components: {
    SwitchButton,
  },
  data() {
    return {
      service: String(process.env.VUE_APP_CLIENT_ID),
      lang: AppConstant.LANGUAGE_VI,
      OtpConstant: OtpConstant,
      endTimes: {
        [OtpConstant.OTP_TYPE_VERIFY_BEFORE_REGISTER]: {
          1: 'cancelLoginSms',
          2: 'cancelLoginEmail',
        },
        [OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE]: {
          1: 'unsubscribeSms',
          2: 'unsubscribeEmail',
        },
        [OtpConstant.OTP_TYPE_VERIFY_TRANSACTION]: {
          1: 'verifyTransactionSms',
          2: 'verifyTransactionEmail',
        },
        [OtpConstant.OTP_TYPE_VERIFY_REGISTER]: {
          1: 'verifyRegisterSms',
          2: 'verifyRegisterEmail',
        }
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentications/getUserLogin'
    })
  },
  methods: {
    isset: helper.isset,
    ...mapActions('authentications', ['logout']),
    handleLogout() {
      this.logout();
      Object.keys(this.endTimes).forEach(key => {
        localStorage.removeItem(this.endTimes[key][OtpConstant.OTP_METHOD_SMS]);
        localStorage.removeItem(this.endTimes[key][OtpConstant.OTP_METHOD_EMAIL]);
      });
    },
    changeService(value, option) {
      this.service = String(process.env.VUE_APP_CLIENT_ID);
      window.open(option.key);
    },
    toggleMenu() {
      document.querySelector('body').classList.toggle('toggle-sidebar');
      document.querySelector('.overlay').classList.toggle('active');
    },
    onChangeLang(value) {
      this.lang = value.lang;
    },
  }
};
</script>

<style>
#switch-port .ant-select-selection-selected-value{
  font-size: 13px;
}
  .z-998 {
    z-index: 998;
  }
  .z-999 {
    z-index: 999;
  }
  .service {
    width: 180px;
    margin-right: 18px !important;
  }
  .service-dropdown {
    width: 180px;
  }
  .dropdown-avatar {
    padding-right: 1rem;
  }
  .dropdown-avatar .dropdown-menu {
    min-width: 160px !important;
  }
  @media screen and (max-width: 767px) {
    .service {
      width: 110px;
      margin-right: 8px !important;
    }
    .service-dropdown {
      width: 160px !important;
    }
    .dropdown-avatar {
      padding-right: 0;
    }
    .dropdown-avatar-link {
      padding-left: 8px !important;
    }
  }
</style>
