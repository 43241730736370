const CURRENCY_NAME = {
  'USD': 'US DOLLAR',
  'EUR': 'EURO',
  'GBP': 'POUND STERLING',
  'JPY': 'YEN',
  'CNY': 'YUAN RENMINBI',
  'AUD': 'AUSTRALIAN DOLLAR',
  'KRW': 'KOREAN WON', 
  'CAD': 'CANADIAN DOLLAR',
  'HKD': 'HONGKONG DOLLAR',
  'INR': 'INDIAN RUPEE',
};

export default {
  CURRENCY_NAME,
}
